<template>
  <div class="exhibit">
    <Header class="Header" ref="Header"></Header>
    <div class="viewMap" :style="bgImg">
      <div class="genBgUrl" :style="genBgUrl"></div>
      <Danmaku ref="Danmaku" class="Danmaku" v-bind:class="{coyBg:!commentStyle}"></Danmaku>
    </div>
    <div class="nav">
      <div class="search" @click="openSearch"></div>
      <div class="barrage" v-if="commentStyle" @click="opencomment"></div>
      <div class="barrages" v-else @click="opencomment"></div>

      <div class="input">
        <input type="text" placeholder="请输入弹幕" v-model="comment"/>
        <span @click="setcomment">发送</span>
      </div>
    </div>

    <div class="navs" v-if="searchInit">
      <div class="block right" v-bind:class="{highlight:popular=='1'}" @click="openPopular(1)">本期作品</div>
      <div class="block" v-bind:class="{highlight:popular=='2'}" @click="openPopular(2)">热门排行</div>
      <div class="block" v-bind:class="{highlight:popular=='3'}" @click="openPopular(3)">往期作品</div>
      <div class="introduce" @click="openIntroduce" v-bind:class="{introduces:popular=='4'}">活动介绍</div>
    </div>
    <!--    -->
    <div class="navs" v-if="popular=='1'&&searchStart=='1'&&!searchInit">
      <div class="Reset" @click="ResetSearch">重置</div>
    </div>
    <div class="keyWords" v-if="search.length>=1&&popular=='1'&&searchStart=='0'">
      <div class="block" v-for="(item,index) in search" :key="index"><span>{{ item }}</span><i
          @click="deletesearch(index)"></i></div>
    </div>
    <List ref="List" :style="listheight" v-if="popular=='1'||popular=='2'"></List>
    <PreviousPeriod :style="listheight" ref="PreviousPeriod" v-if="popular=='3'"></PreviousPeriod>
    <introduce ref="introduce" :style="listheight" v-if="popular=='4'"></introduce>
    <div class="searchView" v-if="searchView">
      <div class="searchtitle">
        <div class="block" v-bind:class="{searchStart:searchStart=='0'}" @click="openSearchStart(0)">普通搜索
        </div>
        <div class="block blocks" v-bind:class="{searchStart:searchStart=='1'}" @click="openSearchStart(1)">序号搜索</div>
      </div>
      <div class="input">
        <input type="text" v-model="searchData"/>
        <div class="set" @click="setSearch(1)"></div>
      </div>
      <div class="close" @click="closesearch"></div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header";
import List from "./com/List";

import Danmaku from "./com/Danmaku";
import introduce from './com/introduce'
import PreviousPeriod from "./com/PreviousPeriod";
import {fixed} from '../../api/introduce'
import {addComment, findSearch, popularFind, findSequenceSearch} from "../../api/exhibit";

import {Toast} from "vant";


export default {

  data() {
    return {
      searchStart: 0,
      listheight: '',
      titleNumber: '',

      commentStyle: true,
      bgImg: '',
      comment: '',

      search: [],
      searchInit: true,
      searchView: false,
      searchData: '',
      popular: 1,
      popularData: [],
      genBgUrl: '',
      list: []
    }
  },
  created() {
    // setTimeout(()=>{
    //   //调用全局方法
    //   this.$root.$emit("init")
    // },500)
    this.init()
    let listheight = document.documentElement.clientHeight - 60 - 250 - 55 - 30 - 5
    listheight = 'height:' + listheight + 'px'
    this.listheight = listheight
  },
  methods: {
    initHomeData(){
      this.$refs.Header.initHomeData()
    },
    ResetSearch() {
      this.searchStart = '1'
      this.searchData = ''
      this.searchInit = true
      this.openPopular(1)
    },
    openSearchStart(str) {
      this.searchStart = str
      this.searchData = ''
    },
    openIntroduce() {
      this.popular = 4
    },
    test() {
      this.$router.push('/zju/Stars')
    },
    openSearch() {
      popularFind().then(res => {
        this.searchView = true
        let data = res.data
        this.popularData = data
      })
    },
    openPopular(str) {
      if (str == 1) {
        this.popular = 1
        this.searchStart = '0'
        setTimeout(() => {
          this.$refs.List.init(this.titleNumber)
        }, 100)
      } else if (str == 2) {
        this.popular = 2
        setTimeout(() => {
          this.$refs.List.openPopular(this.titleNumber)
        }, 100)
      } else {
        this.popular = 3
        setTimeout(() => {
          this.$refs.PreviousPeriod.init(this.titleNumber)
        }, 100)
      }
    },
    init() {
      fixed().then(res => {
        let num = res.num
        let bg = `background-image: url(${num.bgImg})`
        if (Boolean(num.genStart) == true) {
          this.genBgUrl = `background-image: url(${num.genBgUrl})`
        }
        // let benbg = `background-image: url(${num.genBgUrl})`
        this.bgImg = bg
        this.$refs.List.init(num.titleNumber, num.filoink)
        this.titleNumber = num.titleNumber
      })
    },
    initcanvas() {
      let titleNumber = this.titleNumber
      this.$refs.Canvas.openViews(titleNumber)
    },
    fnsearch(data) {
      this.searchData = data
      this.setSearch()
    },
    deletesearch(index) {
      let search = JSON.parse(JSON.stringify(this.search))
      search.splice(index, 1);
      if (search.length == 0) {
        this.search = []
        this.init()
        this.searchInit = true
      } else {
        let titleNumber = this.titleNumber
        findSearch({titleNumber, search}).then(res => {
          if (res.code == 0) {
            this.search = search
            let list = res.list
            if (list.length >= 1) {
              this.$refs.List.inSearch(list)
              this.list = list
              this.searchData = ''
              this.searchView = false
              this.searchInit = false
            } else {
              Toast('暂无数据');
            }
          }
        })
      }
    },
    setSearch() {
      this.popular = 1

      let titleNumber = this.titleNumber
      let data = this.searchData
      if (data) {
        this.list = []
        let searchStart = this.searchStart
        if (searchStart == '0') {
          let search = JSON.parse(JSON.stringify(this.search))
          search.push(data)
          findSearch({titleNumber, search}).then(res => {
            if (res.code == 0) {
              let list = res.list
              if (list.length >= 1) {
                this.search.push(data)
                this.$refs.List.inSearch(list)
                this.list = list
                this.searchData = ''
                this.searchView = false
                this.searchInit = false
              } else {
                Toast('暂无数据');
              }
            } else {
              Toast(res.message);
            }
          })
        } else {
          findSequenceSearch({titleNumber, data}).then(res => {
            let list = res.list
            if (list.length >= 1) {
              this.$refs.List.inSearch(list)
              this.list = list
              this.searchData = ''
              this.searchView = false
              this.searchInit = false
            } else {
              Toast('暂无数据');
            }
          })
        }
      } else {
        Toast('请输入搜索关键词');
      }
    },
    closesearch() {
      this.searchView = false
      this.searchData = ''
    },
    //开启/停止弹幕
    opencomment() {
      let commentStyle = this.commentStyle

      if (commentStyle) {

        this.commentStyle = false
      } else {
        this.commentStyle = true
      }
    },
    //发送评论
    setcomment() {
      let userInfo = this.$store.state.userInfo
      let comment = this.comment
      let titleNumber = this.titleNumber

      if (comment) {
        addComment({userInfo, comment, titleNumber}).then(res => {
          if (res.code == 0) {
            this.$refs.Danmaku.findcomment()
            Toast.success(res.message);
            this.comment = ''
          } else {
            Toast.fail(res.message);
          }
        })
      } else {
        Toast.fail('评论未填写');
      }
    },
  },
  components: {
    Header,
    List,
    Danmaku,
    introduce,
    PreviousPeriod
  },
}
</script>

<style lang="scss">
.exhibit {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  //display: flex;
  //flex-flow: column;
  .viewMap {
    width: 100%;
    height: 250px;
    position: relative;
    background-color: #f0f0f0;
    background-size: 100% 100%;

    .genBgUrl {
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
    }

    .Danmaku {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    .coyBg {
      opacity: 0;
    }
  }

  .nav {
    width: 100%;
    height: 55px;
    font-size: 14px;
    color: #898989;
    background-color: #FFFFFF;
    position: relative;

    .search {
      width: 20px;
      height: 20px;
      background-image: url("../../assets/image/exhibit/search.png");
      background-size: 100% 100%;
      position: absolute;
      left: 25px;
      top: 50%;
      margin-top: -10px;
      cursor: pointer;
      user-select: none;
    }

    .barrage {
      width: 28px;
      height: 23px;
      background-image: url("../../assets/image/exhibit/barrage.png");
      background-size: 100% 100%;
      position: absolute;
      left: 70px;
      top: 50%;
      margin-top: -11.5px;
      cursor: pointer;
      user-select: none;
    }

    .barrages {
      width: 28px;
      height: 23px;
      background-image: url("../../assets/image/exhibit/barrages.png");
      background-size: 100% 100%;
      position: absolute;
      left: 70px;
      top: 50%;
      margin-top: -11.5px;
      cursor: pointer;
      user-select: none;
    }

    span {
      width: 100px;
      text-align: center;
      display: inline-block;
    }

    .input {
      width: 250px;
      position: absolute;
      height: 28px;
      right: 10px;
      top: 50%;
      margin-top: -14px;

      input {
        position: absolute;
        right: 80px;
        top: 0;
        line-height: 28px;
        width: 170px;
        height: 100%;
        padding: 0;
        border: none;
        font-size: 14px;
        text-indent: 1rem;
        background-color: #f1f1f1;
      }

      span {
        display: inline-block;
        background-color: #973733;
        color: #FFFFFF;
        width: 70px;
        font-size: 12px;
        height: 28px;
        line-height: 28px;
        position: absolute;
        right: 0;
        cursor: pointer;
        user-select: none;
      }
    }
  }

  .navs {
    width: 100%;
    height: 30px;
    font-size: 14px;
    color: #898989;
    margin-bottom: 5px;
    background-color: #FFFFFF;
    position: relative;

    .Reset {
      width: 80px;
      height: 20px;
      line-height: 20px;
      border: 1px solid #B8B8B8;
      position: absolute;
      text-align: center;
      left: 10px;
      top: 5px;
    }

    .block {
      margin-left: 10px;
      margin-right: 10px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      display: inline-block;
      cursor: pointer;
      user-select: none;
    }

    .introduce {
      width: 80px;
      height: 20px;
      line-height: 20px;
      border: 1px solid #B8B8B8;
      position: absolute;
      text-align: center;
      right: 10px;
      top: 5px;
    }

    .introduces {
      background-color: #DD7D7D;
      color: #FFFFFF;
      font-size: 11px;
    }

    .right {
      margin-right: 15px;
    }

    .highlight {
      border-bottom: 3px solid #973733;
    }
  }

  .keyWords {
    width: 100%;
    border-bottom: 1px solid rgba(159, 159, 159, 0.5);
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .block {
      font-size: 13px;
      color: #505050;
      background-color: #FFFFFF;
      border: 1px solid #f0f0f0;
      text-align: center;
      min-width: 110px;
      height: 30px;
      line-height: 30px;
      margin: 5px;
      margin-bottom: 12px;

      i {
        width: 30px;
        height: 100%;
        background-size: 10px 10px;
        background-image: url("../../assets/image/exhibit/close.png");
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        float: right;
      }
    }
  }

  .searchView {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0;
    top: 0;

    .close {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 20px;
      top: 50px;
      background-image: url("../../assets/image/exhibit/closes.png");
      background-size: 100% 100%;
    }

    .searchtitle {
      background: none;
      font-size: 15px;
      font-weight: 100;
      letter-spacing: 5px;
      color: #FFFFFF;
      box-shadow: none;
      position: absolute;
      width: 210px;
      height: 30px;
      line-height: 30px;
      margin-left: -140px;
      left: 50%;
      top: 80px;
      text-indent: 0;

      .block {
        display: inline-block;
        width: 100px;
        text-align: center;
        border: 2px solid #FFFFFF;
      }

      .blocks {
        border-left: none;
      }

      .searchStart {
        background-color: #DD7D7D
      }
    }

    .input {
      width: 280px;
      height: 40px;
      background-color: #000000;
      border: 1px solid #f0f0f0;
      position: absolute;
      margin-left: -140px;
      left: 50%;
      top: 135px;

      input {
        width: 230px;
        height: 100%;
        padding: 0;
        border: none;
        background: none;
        text-indent: 1rem;
        color: #f0f0f0;
        position: absolute;
        left: 0;
      }

      .set {
        width: 50px;
        height: 40px;
        background-size: 30px 30px;
        background-position: center center;
        background-image: url("../../assets/image/exhibit/rightSet.png");
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        border-left: 1px solid #f0f0f0;
      }

    }

    .popularTitle {
      position: absolute;
      margin-left: -140px;
      left: 50%;
      top: 200px;
      color: #f0f0f0;
      font-size: 16px;
    }

    .popular {
      width: 280px;
      left: 50%;
      margin-left: -140px;
      //height: 240px;
      position: absolute;
      top: 230px;

      .block {
        width: auto;
        font-size: 12px;
        color: #FFFFFF;
        padding: 5px 8px;
        margin-bottom: 10px;
      }
    }

    .popular:after {
      content: "";
      width: 120px;
    }
  }

}


</style>
