<template>
  <div class="PreviousPeriod" ref="list">
    <div v-if="!popular">
      <div class="block" v-for="(item ,index) in data" :key="index">
        <div class="img" @click="openImg(item.SidNumber,item.id)">
          <img :src="item.imageUrl">
        </div>
        <div class="number">编号：{{ item.SidNumber }}</div>
        <div class="user">作者：{{ item.name }}</div>
        <div class="title">标题：{{ item.penName }}</div>
        <div class="time">时间：{{ item.times }}</div>
      </div>
    </div>

    <div class="addLoad" v-if="data.length>=1&&!Search&&!popular">
      <span @click="LastLoad" class="page">上一页</span>
      <span class="pages">{{ skip }}/{{ pageTol }}</span>
      <span @click="NextLoad" class="page">下一页</span>
    </div>
    <div class="NotData" v-if="data.length==0" v-bind:style="NotData">
      暂无数据
    </div>
    <Load ref="Load"></Load>
    <Details ref="Details"></Details>
    <Prize ref="Prize"></Prize>
  </div>
</template>

<script>
import {findPreviousPeriod} from "../../../api/exhibit";
import {Toast} from "vant";
import Load from "../../../components/load";
import Details from "../../../components/Details";
import Prize from '../../../components/Prize'

export default {
  data() {
    return {
      class: 1,
      skip: 1,
      pageTol: 0,
      titleNumber: '',
      data: [],
      Search: false,
      popular: false,
      NotData: '',
      filoink: {},
    }
  },
  components: {
    Load,
    Details,
    Prize
  },
  mounted() {
    let list = this.$refs.list
    let height = list.style.height
    this.NotData = 'line-height:' + height
  },
  methods: {
    init(titleNumber) {
      this.class = 1
      this.data = []
      this.titleNumber = titleNumber
      this.skip = 1
      this.pageTol = 0
      this.fachdata()
    },
    //打开详情
    openImg(SidNumber) {
      this.$refs.Details.openImg(SidNumber)
    },
    LastLoad() {
      if (this.skip == 1) {
        Toast('已经是第一页了～');
        return
      }
      this.skip--
      this.fachdata()
    },
    NextLoad() {
      if (this.skip == this.pageTol) {
        Toast('已经到底了～');
        return
      }
      this.skip++
      this.fachdata()
    },
    fachdata() {
      this.$refs.Load.open()
      let skip = this.skip
      findPreviousPeriod({skip}).then(res => {
        this.$refs.Load.close()
        let number = res.number
        number = Math.ceil(number / 10)
        this.pageTol = number
        let data = res.sum
        this.data = data
      })
    },
  }
}
</script>

<style scoped lang="scss">
.PreviousPeriod {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .blocks {
    width: 100%;
    height: 100px;
    background-color: #ffffff;
    position: relative;
    margin-bottom: 5px;
    @mixin pos {
      position: absolute;
      font-size: 11px;
    }

    .img {
      position: absolute;
      width: 60px;
      height: 85px;
      left: 30px;
      top: 6px;
      overflow: hidden;
      border: 1px solid #f0f0f0;

      img {
        //transform: rotate(90deg);
        width: 100%;
        height: 100%;
      }
    }

    .number {
      @include pos;
      left: 100px;
      top: 10px;
    }

    .title {
      @include pos;
      left: 100px;
      top: 50px;
    }

    .time {
      @include pos;
      left: 100px;
      top: 70px;
    }

    .user {
      @include pos;
      left: 100px;
      top: 30px;
      width: 120px;
    }
  }

  .block {
    width: 100%;
    height: 100px;
    background-color: #ffffff;
    position: relative;
    margin-bottom: 5px;
    @mixin pos {
      position: absolute;
      font-size: 11px;
    }

    .img {
      position: absolute;
      width: 60px;
      height: 85px;
      left: 30px;
      top: 6px;
      overflow: hidden;
      border: 1px solid #f0f0f0;

      img {
        //transform: rotate(90deg);
        width: 100%;
        height: 100%;
      }
    }

    .number {
      @include pos;
      left: 100px;
      top: 10px;
    }

    .title {
      @include pos;
      left: 100px;
      top: 50px;
    }

    .time {
      @include pos;
      left: 100px;
      top: 70px;
    }

    .user {
      @include pos;
      left: 100px;
      top: 30px;
      width: 120px;
    }

    .great {
      @include pos;
      right: 75px;
      top: 30px;

      i {
        margin-right: 10px;
        display: inline-block;
        background-image: url("../../../assets/image/create/like.png");
        width: 17px;
        height: 17px;
        background-size: 100% 100%;
      }
    }

    .Evaluate {
      @include pos;
      right: 75px;
      top: 60px;

      i {
        margin-right: 10px;
        display: inline-block;
        background-image: url("../../../assets/image/exhibit/openCom.png");
        width: 15px;
        height: 15px;
        background-size: 100% 100%;
      }
    }

    .greats {
      i {
        background-image: url("../../../assets/image/create/likes.png");
      }
    }

    .step {
      @include pos;
      right: 48px;
      top: 40px;


      i {
        margin-right: 10px;
        display: inline-block;
        background-image: url("../../../assets/image/create/step.png");
        width: 17px;
        height: 17px;
        background-size: 100% 100%;
        float: left;

      }

      span {
        float: left;
        display: inline-block;
      }
    }

    .steps {
      i {
        float: left;
        background-image: url("../../../assets/image/create/steps.png");
      }
    }
  }

  .addLoad {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 13px;
    color: #000;

    display: flex;
    justify-content: space-around;

    .page {
      color: #973733;
      cursor: pointer;
      user-select: none;
    }

    .pages {
      color: #666666;
      cursor: pointer;
      user-select: none;
    }
  }

  .NotData {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #666666;
    text-align: center;

  }
}

.list::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.list::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}

.list::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #ededed;
}
</style>
