<template>
  <div class="introduce">
    <div class="data">

      <p>2023年5月21日，浙江大学将迎来126周岁生日。3Q Lab希望再次与浙大师生校友一起，将对母校的祝福寄于画笔，共同创作万人共创的NFT画作——“灿若星辰”NFT，献礼浙江大学校庆。
      </p>
      <span class="bule">规则说明：</span><br>
      <p>此次万人添画@浙江大学<span class="red">【灿若星辰NFT】</span> 活动，将一幅画作分割为近10w份画布，并赋予其坐标，zjuer们可以通过随即或指定坐标选取自己的画布并进行作画。作品版权通过区块链技术存证，版权归每位作者所有，所有作者将免费获得最后成品的NFT（超清作品画布与视频）。未来，你的画作可能成为你在数字艺术世界的第一份认证，在广袤的元宇宙中与母校永久相连。未来，作品有机会在紫金港校区进行线下展览，遥寄你对母校的祝愿。
      </p>
      <p><span class="red">【灿若星辰】</span>当画总数作数累积到一定数字，平台会点亮一盏象征着星辰的求是灯，参与画作越多，求是灯会越明亮，当画作总数达到12600幅，求是灯会全部点亮，触发活动隐藏彩蛋。
      </p>
      <p><span class="red">【丰厚奖品】</span>创作过程中Zjuer们还可以通过分享作品海报、发送弹幕的方式获得点赞数，赢取最终大奖
      </p>
      <p><span class="bule">最佳作品奖1名</span> ：点赞数最高的作品，奖品：iPad *1
      <p><span class="bule">人气作品奖49名</span>：点赞数排行2-50的作品，奖品：幸运彩蛋——现金红包。</p>

      <p>此次“万人添画@浙江大学”活动，在<b>浙江大学创新创业学院</b>指导下，由<b>浙江大学在校生创业团队3Q Lab、浙江大学求是强鹰俱乐部</b>联合推出。</p>
      <p><span class="red">关于 求是强鹰：</span></p>
      <p>
        浙江大学学生求是强鹰俱乐部由浙江大学创新创业学院指导，依托坚持16年的“求是强鹰实践成长计划”，以“推动青年创新创业，引领青年正能量”为出发点，结合新时代对创新创业人才的呼唤和需求，着力提升青年大学生勇于求索、明辨是非、自强不息、敏锐似鹰的精神，培养具有全球视野与家国情怀、践行求是精神、承担社会责任的青年力量。</p>
      <p><span class="red">关于 3Qlab：</span></p>
      <p>诞生于2019年7月3日的浙江大学紫金港校区的北街咖啡。是由科学、艺术和技术领域专业人士组成的DAC（Decentralized Autonomous
        Community，去中心化自治共同体），我们立足浙大，放眼全球，聚焦跨学科跨领域协作，基于区块链技术，以金融、科学与艺术三驾齐驱，探索未来的无限可能，拓展人类的认知边界，期待更多小伙伴加入我们！</p>
      <p style="text-indent: 0">    <span class="bule">联系人：陈俊茹 15325882227</span></p>

    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.introduce {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  overflow-y: scroll;
  font-size: 14px;
  line-height: 35px;

  .data {
    width: 90%;
    margin: 0 auto;
    p{
      text-indent: 2rem;
    }

    .center {
      text-align: center;
    }

    .red {
      color: #c00000;
      font-weight: bold;
    }

    .bule {
      font-weight: bold;
      color: #0000ff;
    }

    img {
      display: block;
      width: 200px;
      margin: 0 auto;
    }
  }
}
</style>
